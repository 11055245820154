// import { getEssayRecordData } from '@/api/writing/essay';
// import { getWritingTask2EvaluationDraft, getWritingTask2ScoreInfo } from '@/api/writing/evaluation';

const extractDomainSuffix = (url: string) => {
  const regex = /\.([a-zA-Z]{2,})(?=[\s/]|$)/; // 正则表达式
  const match = url.match(regex); // 尝试匹配
    
  if (match) {
    return match[1]; // 返回后缀部分
  } else {
    return null; // 如果未找到，返回 null
  }
};

const env = extractDomainSuffix(window.location.origin);
const h5_origin = env ? `https://uniapp.dauyan.${env}` : 'http://localhost:10086';
// const taskPage = 'pages/writing-correct/index';
const feedbackPage1 = 'pages/writing-correct-feedback/index/?type=1&id=';
const feedbackPage2 = 'pages/writing-correct-feedback/index/?type=2&id=';
export const gotoH5Page = () => {
  const hash = window.location.hash;
  if (hash.includes('task-1')){
    const id = getId(hash);
    if (id){
      window.location.replace(`${h5_origin}/#/${feedbackPage1}${id}`);
      return true;
    }
  } else if (hash.includes('task-2')){
    const id = getId(hash);
    if (id){
      window.location.replace(`${h5_origin}/#/${feedbackPage2}${id}`);
      return true;
    }
  }
};

const getId = (text: string) => {
  const values = text.split('/');
  const v = values.findLast(v => v.length > 0);
  console.log(v);
  return v;
};