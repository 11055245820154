import { Navigate, RouteProps } from 'react-router-dom';
import { withLazy } from '@/components/Lazy';
import { Redirect } from '@/modules/PageFramework/Redirect';
import { HomePage } from '../HomePage';

const LazyReadingList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-reading_list" */'@/modules/List/ReadingList').then((res) => res.ReadingList));
const LazyListeningList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-listening_list" */'@/modules/List/ListeningList').then(res => res.ListeningList));
const LazyWritingList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-writing_list" */'@/modules/List/WritingList').then(res => res.App));
const LazyPracticeHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-practce_history_list" */'@/modules/List/HistoryList/PracticeHistory').then(res => res.PracticeHistory));
const LazyMockList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-mock_history_list" */'@/pages/Simulated/App').then(res => res.App));
const LazySampleEssayHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-sample_essay_history_list" */'@/modules/List/HistoryList/SampleEssayHistory').then(res => res.SampleEssayHistory));
const LazyWritingTask2EvaluationHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-evaluation_2_history_list" */'@/modules/List/HistoryList/WritingTask2EvaluationHistory').then(res => res.WritingTask2EvaluationHistory));
const LazyWritingTask1EvaluationHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-evaluation_1_history_list" */'@/modules/List/HistoryList/WritingTask1EvaluationHistory').then(res => res.WritingTask1EvaluationHistory));
const LazyMockTestHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-mock_test_list" */'@/modules/List/HistoryList/MockTestHistory').then(res => res.MockTestHistory));
const LazyModelEssay = withLazy(() => import(/* webpackChunkName: "lazy-ielts-sample_essay" */'@/modules/ModelEssay').then(res => res.ModelEssay));
const LazyWritingEvaluation = withLazy(() => import(/* webpackChunkName: "lazy-ielts-writing_task_2_evaluation" */'@/pages/Writing/sub-pages/Evaluation').then(res => res.WritingEvaluation));
const LazyWritingTask1Evaluation = withLazy(() => import(/* webpackChunkName: "lazy-ielts-writing_task_1_evaluation" */'@/pages/Writing/sub-pages/Essay/EssayEntry').then(res => res.EssayEntry));
const LazyTrainingList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-training-list" */'@/modules/List/TrainingList').then(res => res.TrainingList));
const LazyBasicTrainingHistory = withLazy(() => import(/* webpackChunkName: "lazy-ielts-training-history" */'@/modules/List/HistoryList/BasicTraining').then(res => res.BasicTrainingHistory));
const LazyCourseList = withLazy(() => import(/* webpackChunkName: "lazy-ielts-course-list" */'../CoursePage').then(res => res.CoursePage));
const LazyCourseVideo = withLazy(() => import(/* webpackChunkName: "lazy-ielts-course-video" */'@/modules/Courses/VideoCourse').then(res => res.VideoCourse));


export const routers: RouteProps[] = [
  { path: '/', Component: HomePage },

  // AI 工具

  /** @deprecated */
  { path: '/big-essay/:recordId?', Component: LazyWritingEvaluation },
  /** @deprecated */
  { path: '/small-essay/:id?', Component: LazyWritingTask1Evaluation },
  /** @deprecated */
  { path: '/small-essay/:id/:recordId?', Component: LazyWritingTask1Evaluation },

  { path: '/writing-task-2/:recordId?', Component: LazyWritingEvaluation },
  { path: '/writing-task-1/:id?/:recordId?', Component: LazyWritingTask1Evaluation },

  { path: '/writing-feedback-task-2/:recordId?', Component: LazyWritingEvaluation },
  { path: '/writing-feedback-task-1/:id?/:recordId?', Component: LazyWritingTask1Evaluation },

  { path: '/model-essay', Component: LazyModelEssay },
  { path: '/model-essay-result', Component: LazyModelEssay },

  // 基础练习
  { path: '/training-list/:type?', Component: LazyTrainingList },


  // 强化练习

  { path: '/writing-list', element: <Navigate to='/writing-list/1' replace /> },
  { path: '/writing-list/:module_id', Component: LazyWritingList },

  { path: '/reading-list', Component: LazyReadingList },
  { path: '/listening-list', Component: LazyListeningList },

  // 视频课

  // 课程列表
  { path: '/course/:id', Component: LazyCourseList },
  // 视频课
  { path: '/course-video/:id/:recordId?', Component: LazyCourseVideo },


  // 模考
  { path: '/mock_list', Component: LazyMockList },

  // 历史记录
  { path: '/history/practice/', Component: LazyPracticeHistory },
  { path: '/history/practice/:type', Component: LazyPracticeHistory },
  { path: '/history/mock_test', Component: LazyMockTestHistory },
  { path: '/history/sample_essay', Component: LazySampleEssayHistory },
  { path: '/history/evaluation', Component: LazyWritingTask2EvaluationHistory },
  { path: '/history/essay', Component: LazyWritingTask1EvaluationHistory },
  { path: '/history/basic_training', Component: LazyBasicTrainingHistory },
  { path: '/history', Component: LazyPracticeHistory },

  { path: '/*', Component: Redirect },
];