import { createSlice } from '@reduxjs/toolkit';
export interface IHomeHeaderState{
  backUrl?: string;
}
const initialState: IHomeHeaderState = {
  
};
export const homeHeaderSlice = createSlice({
  name: 'homeHeader',
  initialState,
  reducers: {
    setBackUrl: (state, action: { payload: string; }) => {
      state.backUrl = action.payload;
    },
  }
});
export const HomeHeaderActions = homeHeaderSlice.actions;
