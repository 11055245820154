import React from 'react';
import classnames from 'classnames';
import { SimpleButton } from '@/components/Simple/SimpleButton';
import { UserInfo } from '@/modules/UserInfo/lazy';
import { AdviceTel } from '@/modules/PageFramework/AdviceTel';
import { useAppSelector } from '@/stores';
import { Breadcrumb } from '../Breadcrumb/';
import css from './home.header.module.less';

export const HomeHeader = () => {
  const {backUrl} = useAppSelector(state => state.homeHeader);
  return (
    <div className={classnames(css['container'])}>
      <div className={classnames(css['left'])}>
        <Breadcrumb />
        {
          backUrl && (
            <SimpleButton duyan_type='dashed' className={css['back-btn']} onClick={()=>{
              window.location.href = backUrl;
            }}>返回</SimpleButton>
          )
        }
      </div>
      <div className={classnames(css['right'])}>
        <AdviceTel className={css['right_item']} />
        <UserInfo />
      </div>
    </div>
  );
};