import ReactDOM from 'react-dom/client';
import { InputRef, Modal } from 'antd';
import React, { useRef } from 'react';
import toast from 'react-hot-toast';
import { useMemoizedFn } from 'ahooks';
import { SimpleButton } from '@/components/Simple/SimpleButton';
import { SimpleInput } from '@/components/Simple/SimpleInput';
import i18n from '@/locales/init';
import { postVoucher } from '@/api/entitlements';
import lessModule from './redeem.module.less';

export interface IRedeemCodeModalProps {
  desc?: string;
  onClose: () => void;
}

const RedeemCodeComp = React.memo<IRedeemCodeModalProps>(({ onClose, desc = '' }) => {
  const inputRef = useRef<InputRef>();
  const codeRef = useRef<string>('');

  const onConfirm = useMemoizedFn(() => {
    console.log('Code', codeRef.current);
    const v = codeRef.current || inputRef.current?.input?.value || '';
    if (!v) {
      toast.error(i18n.t('请先输入兑换码'));
      return;
    }
    postVoucher(v).then((res) => {
      console.log('[Result]', res);
      if (res.code === 200) {
        if (res.data) {
          toast.success(i18n.t('兑换成功! 获得 {{desc}} x {{num}}', { desc: res.data.type, num: String(res.data.num)}));
          onClose();
        }
      }
    }).catch((e) => {
      console.log('E', e);
      if (e.response.data.msg) {
        toast.error(e.response.data.msg);
      } else {
        toast.error(i18n.t('common.dauyan_account_redemption_failed'));
      }
    });
  });

  return <div className={lessModule.wrapper}>
    <div className={lessModule.title}>{i18n.t('兑换码')}</div>
    <div className={lessModule.inputline}>
      <div className={lessModule.inputEle}>
        <SimpleInput onChange={(e) => codeRef.current = e.target.value} placeholder={i18n.t('请输入兑换码')} className={lessModule.input} ref={inputRef}></SimpleInput>
        {
          desc ? <div className={lessModule.desc}>{desc}</div>
            : null
        }
      </div>
    </div>
    <div className={lessModule.footer}>
      <SimpleButton data-track-key={'entitlement-exchange-cancel'} duyan_type='normal' className={lessModule.cancel} onClick={onClose}>{i18n.t('取消')}</SimpleButton>
      <SimpleButton data-track-key={'entitlement-exchange-confirm'} duyan_type='standard' className={lessModule.confirm} onClick={onConfirm}>{i18n.t('立即兑换')}</SimpleButton>
    </div>
  </div>;
});

let parentNode: HTMLDivElement | null = null;
let root: any = null;

export const RedeemCodeModal = Object.assign({}, RedeemCodeComp, {
  open: (props: IRedeemCodeModalProps) => {
    if (!parentNode) {
      parentNode = document.createElement('div');
      document.body.appendChild(parentNode);
    }
    if (root) {
      root.unmount();
      root = null;
    }
    const onClose = () => {
      props.onClose();
      if (root) {
        root.unmount();
        root = null;
      }
    };

    root = ReactDOM.createRoot(parentNode);
    root.render(<Modal
      open
      footer={null}
      width={480}
      centered
      className={lessModule.modalWrapper}
      onCancel={onClose}
    ><RedeemCodeComp {...props} onClose={onClose} /></Modal>);
  },

  destroy: () => {
    if (root) {
      root.unmount();
      root = null;
    }
  },
});