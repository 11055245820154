import React, { useCallback, useEffect } from 'react';
import { Layout, theme } from 'antd';
import classnames from 'classnames';
import { Route, Routes, } from 'react-router';
import { Menu } from '@/pages/Home/Menu';
import { HeaderLogo } from '@/components/Icon/HeaderLogo';
import { scaleWindow } from '@/utils/scaleWindow';
import { PageBottom } from '@/modules/PageFramework/PageBottom';
import { useAppDispatch } from '@/stores';
import { fetchUserMenus } from '@/stores/slices/userSlice';
import { routers } from './Router/index';
import { HomeHeader } from './HomeHeader';

import css from './layout.module.less';


const { Header, Content, Footer, Sider } = Layout;

scaleWindow();

const App: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const dispatch = useAppDispatch();

  const init = useCallback(async () => {
    dispatch(fetchUserMenus());
  }, []);

  useEffect(() => {
    init();
  }, []);

  return (
    <Layout className={classnames(css['home'])}>
      <Layout className={classnames(css['home_body'])}>
        <Sider
          theme='light'
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <div className={classnames(css['home-logo'])}>
            <HeaderLogo style={{ height: 45 }} />
          </div>
          <div className={classnames(css['home-menu'])} >
            <Menu />
          </div>
        </Sider>

        <Layout>
          <Header style={{ padding: 0, background: colorBgContainer }} className={css['home-header']} >
            <HomeHeader />
          </Header>
          <Content className={classnames(css['home-content'])}>
            <Routes>
              {
                routers.map((item) => {
                  return <Route key={item.path} {...item} />;
                })
              }
            </Routes>
          </Content>
        </Layout>

      </Layout>
      <Footer className={classnames(css['home-footer'])}>
        <PageBottom />
      </Footer>
    </Layout>
  );
};

export default App;