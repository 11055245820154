import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { useMemoizedFn } from 'ahooks';
import classNames from 'classnames';
import { logCount } from '@/utils/logger/arms-config';
import { SimpleButton } from '@/components/Simple/SimpleButton';
import { IWritingTask2SampleEssay } from '@/api/history';
import { reBigEssaySubjectApi } from '@/api/writing/modelEssay';
import i18n from '@/locales/init';
import { StatusLabel } from '../../components/StatusLabel';
import lessModule from './item.module.less';

const getStatus = (state: number) => {
  if (state === 1) {
    return i18n.t('common.dauyan_in_progress');
  }
  return i18n.t('common.dauyan_some_complete');
};

export interface IWritingTask2SampleEssayItemProps {
  item: IWritingTask2SampleEssay;
  onHomePage?: boolean;
}

export const WritingTask2SampleEssayItem = React.memo<IWritingTask2SampleEssayItemProps>(({
  item, onHomePage
}) => {

  const handleClick = useMemoizedFn(() => {
    logCount('我的范文历史记录进入详情');
    window.location.href = `${window.location.origin}/#/model-essay-result?record_id=${item.id}&from=list`;
  });

  const disabled = useMemo(() => {
    return item.paper_id === 0 && (item as any).subject === '';
  }, [item]);

  return <div className={classNames(lessModule.itemWrapper, onHomePage && lessModule.onHomePage)} data-track-key={'history-sampleessay-review'} onClick={handleClick}>
    <div className={lessModule.title}>{item.paper_title}</div>
    <div className={lessModule.content}>{item.paper_content || i18n.t('common.dauyan_write_no_fill_in')}</div>
    <div className={lessModule.status}>
      <StatusLabel text={getStatus(item.status)} complete={item.status === 2}></StatusLabel>
    </div>
    <div className={lessModule.time}>{dayjs(item.update_time * 1000).format('YYYY-MM-DD HH:mm')}</div>
    <div className={lessModule.redo}>
      {!disabled && item.status === 2 && <SimpleButton data-track-key={'history-sampleessay-restart'} onClick={async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const res = await reBigEssaySubjectApi({ id: item.id });
        if (res.code === 200) {
          window.location.href = `${window.location.origin}/#/model-essay?record_id=${res.data.id}`;
        }
      }}>{i18n.t('common.dauyan_restart_task2')}</SimpleButton>}
    </div>
  </div>;
});
