import { t } from 'i18next';
import { useMemoizedFn } from 'ahooks';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { SimpleInput } from '@/components/Simple/SimpleInput';
import css from './index.module.less';

interface SearchItemProps{
  onSearch?: (content:string)=>void;
  content?:string;
  style?:CSSProperties;
  className?:string;
}
const maxSearchLength = 60;
export const SearchItem = (props:SearchItemProps)=>{
  const [searchContent, setSearchContent] = useState<string>('');
  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && e.keyCode === 13) {
      e.preventDefault();
      (e.target as HTMLInputElement).blur();
      props.onSearch && props.onSearch(searchContent);
    }
  }, [searchContent]);
  const handleSetSearchContent = (value:string)=>{
    setSearchContent(value.substring(0, maxSearchLength));
  };
  
  const handleSearch = useMemoizedFn(() => {
    props.onSearch && props.onSearch(searchContent);
  });
  const clearSearch = useMemoizedFn(() => {
    setSearchContent('');
    props.onSearch && props.onSearch('');
  });

  useEffect(()=>{
    const content = props.content === undefined ? '' : props.content;
    setSearchContent(content.substring(0, maxSearchLength));
  },[props.content]);

  const s1 = useMemo(() => {
    return { left: '8px' };
  }, []);
  const s2 = useMemo(() => {
    return { right: '8px' };
  }, []);

  return <div className={props.className} style={props.style}>
    <div>
      <SimpleInput
        className={css.topicSearchInput} placeholder={t('common.dauyan_exercise_search')} 
        value={searchContent}
        onKeyDown={handleKeyDown} 
        onChange={(e) => handleSetSearchContent(e.target.value)} />
      <SearchOutlined className={css.topicSearchIcon} style={s1} onClick={handleSearch} />
      {
        searchContent &&
                  <CloseCircleOutlined className={css.topicSearchIcon} style={s2} onClick={clearSearch} />
      }
    </div>
  </div>;
};