/**
 * ARMs配置
 */
import * as BrowserLogger from '@arms/js-sdk';
import { isRemote, isProduction, version } from '../env';
import { getPageName } from './get-page-name';

class devLogger {
  public sum(key: string, value: number) {
    console.log('sum', key, value);
  }

  public avg(key: string, value: number) {
    console.log('avg', key, value);
  }

  public error(error: Error | string) {
    console.log('error', error);
  }

  public setPage(page: string) {
    console.log('setPage', page);
  }

  public setConfig(conf: unknown) {
    console.log('setLogConfig', conf);
  }

  public api(info: unknown) {
    console.log('api', info);
  }
}


let prefix = '';
const defaultSetUserName = () => null;

// 暂时先用自定义类实现来代替开发环境下的日志上报
// 主要是为了 * 禁止开发环境下的日志上报 *
// eslint-disable-next-line no-constant-condition
const logger = isRemote || true ? BrowserLogger.singleton({
  pid: 'c17fpe3hwh@1951c92bd5cfad8',
  appType: 'web',
  imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?', // 上报地址
  sample: 1, // 采样率
  behavior: true,
  enableSPA: true,
  parseHash: getPageName,
  useFmp: true,
  sendResource: true,
  enableLinkTrace: true,
  page: getPageName(),
  release: version,
  environment: isRemote ? (isProduction ? 'prod' : 'pre') : 'local',
  setUserName: defaultSetUserName,
  ignore: {
    // ignoreUrls: [],
    ignoreApis: [
      /hot\.json$/
    ],
    // ignoreErrors: [],
    // ignoreResErrors: []
  },
}) : new devLogger();

export const setLogConfig = (config: { [key: string]: string; }) => {
  logger.setConfig(config);
};

export const setUserName = (str: string) => {
  logger.setConfig({
    setUsername: function () {
      return str;
    }
  });
};

export const updatePage = () => {
  logger.setPage(getPageName());
};

/**
 * 计数
 * @param key 
 * @param count 
 */
export const logCount = (key: string, count = 1) => {
  logger.sum(`${prefix}${key}`, count);
};

/**
 * 行为
 * @param key 
 */
export const logAction = (key: string) => {
  logger.sum(`${prefix}${key}`, 1);
};

/**
 * 统计均值
 * @param key 
 * @param value 
 */
export const logAvg = (key: string, value: number) => {
  logger.avg(`${prefix}${key}`, value);
};

const _logOnceRecord: { [key: string]: 1; } = {};

/**
 * 只上报一次
 * @param key 
 */
export const logOnce = (key: string) => {
  if (_logOnceRecord[key]) {
    _logOnceRecord[key] = 1;
    logger.sum(`${prefix}${key}`, 1);
  }
};

const _logDurationRecord: {
  [key: string]: number;
} = {};

export const logDurationStart = (key: string) => {
  _logDurationRecord[key] = Date.now();
};

export const logDurationEnd = (key: string) => {
  if (_logDurationRecord[key]) {
    const value = Date.now() - _logDurationRecord[key];
    delete _logDurationRecord[key];
    logger.avg(`${prefix}${key}`, value);
  }
};

export const logError = (error: Error | string) => {
  logger.error(error);
};

export const logInfo = (data: { [key: string]: any; }) => {
  logger.custom({
    custom: JSON.stringify(data),
  });
};

export const logApi = (info: {
  api: string;
  success: boolean;
  time: number; // 耗时
  code?: number;
  msg?: string;
  begin?: number;
  traceId?: string;
  sid?: string;
}) => {
  logger.api(info);
};

export const setPrefix = (s: string) => {
  prefix = s;
};

export const armsLogger = {
  logCount,
  logAction,
  logAvg,
  logOnce,
  logDurationStart,
  logDurationEnd,
  logError,
  logInfo,
};

window.addEventListener('error', (event) => {
  if (event.error) {
    logError(event.error as any);
  } else {
    logError(event as any);
  }
  logger.sum('Unhandled Error', 1);
});

export default armsLogger;

export type ILoggerType = typeof armsLogger;
