import React, { useEffect } from 'react';
import { useMemoizedFn } from 'ahooks';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SimpleImagePro } from '@/components/Simple/SimpleImagePro';
import { VipBadgeIcon } from '@/components/Icon/VipBadgeIcon';
import { navigateWithNewWindow } from '@/utils/navigate';
import { PUBLIC_PATH } from '@/utils/const';
import { SimpleButton } from '@/components/Simple/SimpleButton';
import { VipIcon2 } from '@/components/Icon/VipIcon2';
import { SimpleIconFont } from '@/components/Simple/SimpleIconFont';
import { PointsRedIcon } from '@/components/Icon/PointsRed';
import { PointsPurpleIcon } from '@/components/Icon/PointsPurple';
import { ExchangeIcon } from '@/components/Icon/ExchangeIcon';
import store, { useAppSelector } from '@/stores';
import i18n from '@/locales/init';
import { login, updateUserInfo } from '@/modules/Permissions';
import { pay } from '@/modules/Permissions/utils/pay';
import { RedeemCodeModal } from '@/modules/Entitlements/RedemptionCode/RedeemCodeModal';
import { fetchUserPoints } from '@/stores/slices/userSlice';
import lessModule from './section-user.module.less';

dayjs.extend(utc);

export const SectionUser = React.memo(() => {
  const isLogin = useAppSelector(state => state.user.userPermission.isLogin);
  const userMeta = useAppSelector(state => state.user.userMeta);
  const isVip = useAppSelector(state => !!state.user.userPermission.ai?.isVip);
  const expireTime = useAppSelector(state => state.user.userPermission.expirationTime);
  const inited = useAppSelector(state => state.user.userInfoInital);
  const correctionPoints = useAppSelector(state => state.user.userPermission.points.writingCorrection);
  const sampleEssayPoints = useAppSelector(state => state.user.userPermission.points.writingSampleEssay);

  useEffect(()=>{
    store.dispatch(fetchUserPoints());
  },[]);

  const handleClickAvatar = useMemoizedFn(() => {
    if (isLogin) {
      navigateWithNewWindow(`${window.location.origin}${PUBLIC_PATH}profile/`);
    } else {
      login();
    }
  });

  const handleExchange = useMemoizedFn(() => {
    RedeemCodeModal.open({
      desc: i18n.t('确认兑换到{{mobile}}吗？', { mobile: userMeta.mobile || '当前账号' }),
      onClose: () => {
        updateUserInfo();
      },
    });

  });

  return <>
    <div className={lessModule.left}>
      <div onClick={handleClickAvatar} className={lessModule.avatar}>
        <SimpleImagePro
          imgStyle={{ border: '1px solid #d8d4d4' }}
          // width={64} height={64}
          borderRadius={50}
          src={userMeta.avatar}
        />
      </div>
      {
        isLogin ? <div className={lessModule.userInfo}>
          <div className={lessModule.userName}>
            <span>Hi, </span>
            <span>{userMeta.name}</span>
            {
              isVip ? <span className={lessModule.badgeIcon}><VipBadgeIcon /></span> : null
            }
            {
              isVip ? <span className={lessModule.badgeText}>SVIP</span> : null
            }
          </div>
          <div className={lessModule.userInfoScript}>
            {
              isVip ? <>
                <span>{i18n.t('common.dauyan_out_of_date', { time: dayjs.utc(expireTime * 1000).utc().format('YYYY.MM.DD') })}</span>
                <span><SimpleButton data-track-key={'home-sectionuser-vip'} className={lessModule.simpleButton} onClick={() => pay()}>{i18n.t('common.dauyan_go_renew')}</SimpleButton></span>
              </>
                : <span>
                  <SimpleButton
                    icon={<VipIcon2 />}
                    className={lessModule.vipButton}
                    data-track-key={'home-sectionuser-vip2'} 
                    onClick={() => pay()}
                  >
                    {i18n.t('common.dauyan_open_svip')}
                    <SimpleIconFont className={lessModule.vipIcon} type='icon-dauyan-arrow'/>
                  </SimpleButton>
                </span>
            }
          </div>
        </div> : null
      }
      {
        !isLogin && inited ? <div className={lessModule.userInfo}>
          <div className={lessModule.userName}>{i18n.t('common.dauyan_havent_login')}</div>
          <div><SimpleButton data-track-key={'home-sectionuser-login'} className={lessModule.simpleButton} onClick={() => login()}>{i18n.t('common.dauyan_go_login')}</SimpleButton></div>
        </div> : null
      }
    </div>
    <div className={lessModule.right}>
      {
        isLogin ? 
          <div className={lessModule.rightLine}>
            <span className={lessModule.pointItem}><span className={lessModule.pointIcon}><PointsRedIcon style={{ width: '24px', height: '24px' }} /></span><span>{i18n.t('作文批改券：')}</span><span style={{ fontWeight: 500 }}>{correctionPoints}</span></span>
            {/* <span className={lessModule.pointItem}><span className={lessModule.pointIcon}><PointsPurpleIcon style={{ width: '24px', height: '24px' }} /></span><span>{i18n.t('范文券：')}</span><span>{sampleEssayPoints}</span></span> */}
            <span><SimpleButton data-track-key={'home-sectionuser-exchangepoint'} style={{ paddingLeft: '1rem', paddingRight: '1.5rem' }} icon={<ExchangeIcon style={{ width: '20px', height: '20px', lineHeight: '2rem' }} />} className={lessModule.exchangeButton} onClick={handleExchange}>{i18n.t('兑换')}</SimpleButton></span>
          </div>
          : null
      }
    </div>
  </>;
});

export const UserSectionClassName = lessModule.wrapper;
